/* eslint-disable import/no-cycle */
import { Button, Divider, IconPlusCircle, Tooltip } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { AvailableLayoutModule, ContentUnion, ModuleContainer, QueryItem } from 'data/generated/graphql';
import styles from './AddModuleButton.module.scss';

interface AddModuleButtonProps {
  addableUiModules?: AvailableLayoutModule[];
  availableTreatmentTypes?: string[];
  nextModuleIndex: number;
  sectionIndex?: number;
}

export const AddModuleButtonItp = ({
  addableUiModules,
  availableTreatmentTypes,
  nextModuleIndex,
  sectionIndex
}: AddModuleButtonProps) => {
  const { insertEntity } = useDataModelContext();

  const handleAddModule = () => {
    if (!addableUiModules || (addableUiModules.length === 0 && !availableTreatmentTypes?.length)) {
      return;
    }

    const defaultModule =
      addableUiModules.find((module) => {
        const itpAllowedTreatmentTypes = [
          'ITP_12_STORIES',
          'ITP_2_STORIES',
          'ITP_2_STORIES_WITH_FEATURE_SPOT',
          'ITP_3_STORIES_WITH_FEATURE_SPOT',
          'ITP_5_STORIES_WITH_FEATURE_SPOT',
          'ITP_6_STORIES',
          'ITP_6_STORIES_WITH_FEATURE_SPOT'
        ];
        return module.defaultModule.uiModuleFields.basicModule?.allowedTreatmentTypes?.every((tt) =>
          itpAllowedTreatmentTypes.includes(tt)
        );
      })?.defaultModule ?? null;

    if (!defaultModule) return;

    const collection = defaultModule.moduleItems
      .map((moduleItem) => {
        if (moduleItem.itemFields.queryItem) {
          const queryItem: QueryItem = {
            type: 'Query',
            attributes: {
              isFeed: moduleItem.itemFields.queryItem.isFeed,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              query: JSON.parse(moduleItem.itemFields.queryItem.jsonQuery),
              feedTitle: moduleItem.itemFields.queryItem.title,
              feedUrl: moduleItem.itemFields.queryItem.url
            }
          };
          return queryItem;
        }
        return null;
      })
      .filter((item) => item !== null) as ContentUnion[];

    const newModule: ModuleContainer = {
      type: 'Module',
      attributes: { pageModule: defaultModule },
      collection
    };

    insertEntity(`0-${sectionIndex}-0-${nextModuleIndex}`, newModule);
  };

  if (!addableUiModules || (addableUiModules.length === 0 && !availableTreatmentTypes?.length)) {
    return null;
  }

  return (
    <div className={styles.blockDivider}>
      <div className={styles.blockDividerContent}>
        <Divider className={styles.blockDividerDivider} />
        <Tooltip>
          <Tooltip.Content position={nextModuleIndex === 0 ? 'bottom' : 'top'}>Add a Module Here</Tooltip.Content>
          <Tooltip.Trigger>
            <Button
              tertiary
              icon={IconPlusCircle as SvgComponent}
              onClick={handleAddModule}
              data-testid="add-module-button"
            />
          </Tooltip.Trigger>
        </Tooltip>
        <Divider className={styles.blockDividerDivider} />
      </div>
    </div>
  );
};
