import { useEffect, useState } from 'react';
import { Button, Dialog, Divider, useModalPortal, Wrapper } from '@screentone/core';

import { ContentUnion, PageModule, UiModuleType } from 'data/generated/graphql';
import { PageModuleBasic } from './components/page-module-basic/PageModuleBasic';
import { PageModuleRanked } from './components/page-module-ranked/PageModuleRanked';
import { PageModuleText } from './components/page-module-text/PageModuleText';
import { PageModuleTitle } from './components/page-module-title/PageModuleTitle';
import { PageModuleTwoColumn } from './components/page-module-two-column/PageModuleTwoColumn';
import styles from './PageModuleModal.module.scss';

interface PageModuleModalProps {
  onChange: (newPageModule: PageModule, collection?: ContentUnion[]) => void;
  onDismiss: () => void;
  pageModule: PageModule;
  viewOnly?: boolean;
}

interface PageModuleModalFormProps {
  pageModule: PageModule;
  onChange: (newPageModule: PageModule, collection?: ContentUnion[]) => void;
  viewOnly?: boolean;
}

const PageModuleModalForm = ({ pageModule, onChange, viewOnly }: PageModuleModalFormProps) => {
  const { uiModuleType } = pageModule;
  let childModule = null;

  switch (uiModuleType) {
    case UiModuleType.UiBasicModuleType:
      childModule = <PageModuleBasic />;
      break;
    case UiModuleType.UiRankedModuleType:
      childModule = <PageModuleRanked pageModule={pageModule} onChange={onChange} />;
      break;
    case UiModuleType.UiTitleModuleType:
      childModule = <PageModuleTitle pageModule={pageModule} onChange={onChange} viewOnly={viewOnly} />;
      break;
    case UiModuleType.UiTextModuleType:
      childModule = <PageModuleText pageModule={pageModule} onChange={onChange} />;
      break;
    case UiModuleType.UiBasicOptionalModuleType:
      childModule = <PageModuleBasic />;
      break;
    case UiModuleType.UiFeedsModuleType:
      childModule = <PageModuleBasic />;
      break;
    default:
      childModule = <PageModuleTwoColumn />;
  }

  return childModule;
};

export const PageModuleModal = ({ pageModule, onChange, onDismiss, viewOnly }: PageModuleModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { renderNode } = useModalPortal();
  const [curPageModule, setCurPageModule] = useState(pageModule);
  const { uiModuleType } = curPageModule;

  const handleChange = (newModule: PageModule, collection?: ContentUnion[]) => {
    setCurPageModule(newModule);
    onChange(newModule, collection);
  };

  useEffect(() => {
    setCurPageModule(pageModule);
  }, [pageModule, uiModuleType]);

  const handleSave = () => {
    onChange(curPageModule);
    onDismiss();
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Dialog status="open" onDismiss={onDismiss} focusTrap={false} renderNode={renderNode}>
      <Dialog.Title>{viewOnly ? 'View' : 'Edit'} Module</Dialog.Title>
      <Dialog.Content>
        <PageModuleModalForm pageModule={curPageModule} viewOnly={viewOnly} onChange={handleChange} />
        <Divider className={styles.divider} />
        <Wrapper className={styles.buttons}>
          {!viewOnly && (
            <>
              <Button secondary onClick={onDismiss} margin={{ right: 'sm' }} data-testid="page-treatment-type-cancel">
                Cancel
              </Button>
              <Button primary onClick={handleSave} data-testid="page-treatment-type-save">
                Save
              </Button>
            </>
          )}
        </Wrapper>
      </Dialog.Content>
    </Dialog>
  );
};
