/* eslint-disable import/no-cycle */
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHeaderData } from '@screentone/addon-auth-wrapper';
import {
  Box,
  Button,
  Dropdown,
  Group,
  IconBarrons,
  IconCameraOff,
  IconClipboard,
  IconDisabled,
  IconFnlondon,
  IconMansionglobal,
  IconMarketwatch,
  IconPen,
  IconThreeDotsVer,
  IconWsj,
  IconWsjChina,
  IconWsjcommerce,
  IconWsjJapan,
  IconWsjopinion,
  Token,
  Typography,
  Wrapper
} from '@screentone/core';
import classNames from 'classnames';

import { RemoveEntity } from 'components/context-menu-actions/remove-entity/RemoveEntity';
import { CommonEntityProps } from 'components/datamodel/commonEntityProps';
import { DuplicateItemType } from 'contexts/content-id-tracking/ContentIdTrackingContext';
import { useContentIdTracking } from 'contexts/content-id-tracking/useContentIdTracking';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { HIGHLIGHT_GROUPS } from 'contexts/highlight/HighlightContext';
import { useHighlight } from 'contexts/highlight/useHighlight';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { ArticleItem } from 'data/generated/graphql';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { useContentConstants } from 'hooks/useContentConstants';
import useDebouncedState from 'hooks/useDebouncedState';
import { useImage } from 'hooks/useImage';
import { formatLocalDate } from 'utils/dates';
import { safelyParseContent } from 'utils/temp';
import { decodeHtmlEntities } from 'utils/text';
import { extractSizeFromUrl, resizeIMImage } from 'utils/url';
import styles from './Article.module.scss';
import TypeAndBrandToken from './TypeAndBrandToken';
import { CommonContentProps } from '../commonContentProps';

import { getUrlToShow, getUrlToShowType } from 'components/altsumm-modal/AltSummModal.utils';

const IM_WIDTH = {
  THUMBNAIL: 40,
  ENLARGED: 180
};

const brandIcon = (brand: string, publicationMap: Record<string, string> | undefined) => {
  const props = { size: 'lg', className: styles.image };
  const mappedBrand = publicationMap?.[brand] ?? brand;

  switch (mappedBrand) {
    case 'barrons':
    case 'penta':
      return <IconBarrons {...props} />;
    case 'wsj':
      return <IconWsj {...props} />;
    case 'marketwatch':
      return <IconMarketwatch {...props} />;
    case 'pen':
      return <IconPen {...props} />;
    case 'fnlondon':
      return <IconFnlondon {...props} />;
    case 'mansionglobal':
      return <IconMansionglobal {...props} />;
    case 'jwsj':
      return <IconWsjJapan {...props} />;
    case 'cwsj':
      return <IconWsjChina {...props} />;
    case 'wsjopinion':
      return <IconWsjopinion {...props} />;
    case 'wsjcommerce':
      return <IconWsjcommerce {...props} />;
    case 'ydj':
    default:
      return (
        <div className={styles.noIcon}>
          <IconCameraOff {...props} />
        </div>
      );
  }
};

type ResetErrorBoundaryType = (...args: any[]) => void;

interface ErrorArticleProps {
  allessehId: string;
  hierarchyId: string;
  resetErrorBoundary: ResetErrorBoundaryType;
  isDraft?: boolean;
  noBorder?: boolean;
  boxPadding?: 'xs' | 'sm' | 'smd' | 'md' | 'mlg' | 'lg' | 'xl';
}

const ErrorArticle = ({
  allessehId,
  hierarchyId,
  resetErrorBoundary,
  isDraft,
  noBorder = false,
  boxPadding = 'sm'
}: ErrorArticleProps) => {
  const copyIdToClipboard = async () => {
    await navigator.clipboard.writeText(allessehId);
  };

  useLayoutEffect(
    () => () => {
      resetErrorBoundary();
    },
    [allessehId, resetErrorBoundary]
  );

  return (
    <Box
      padding={{ all: boxPadding }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      className={classNames(styles.errorArticle, { [styles.noBorder]: noBorder })}
      data-allesseh-id={allessehId}
      data-testid="page-content-card-container"
      data-model-hierarchy-id={hierarchyId}
    >
      <Group gap="none">
        <div className={styles.imageContainer} data-testid="content-card-image-container">
          <div className={classNames(styles.image, styles.noImage)} data-testid="thumbnail" />
        </div>

        <Typography
          componentEl="div"
          variant="bodytext"
          margin={{ left: 'sm' }}
          className={styles.headline}
          data-testid="content-card-headline-label"
        >
          This article has an error and cannot be rendered properly. (ID: {allessehId})
          <Button tertiary margin={{ right: 'md' }} icon={IconClipboard as SvgComponent} onClick={copyIdToClipboard}>
            Copy ID
          </Button>
        </Typography>

        {isDraft && (
          <Dropdown
            padding={{ all: 'none' }}
            position="right"
            trigger={<IconThreeDotsVer color="asphalt" />}
            data-testid="page-content-ellipsis-icon"
          >
            <Wrapper onClick={resetErrorBoundary} padding={{ all: 'smd' }}>
              <RemoveEntity hierarchyId={hierarchyId} text="Remove from module" />
            </Wrapper>
          </Dropdown>
        )}
      </Group>
    </Box>
  );
};

export interface ArticleProps extends CommonEntityProps, CommonContentProps {
  data: ArticleItem;
  noBorder?: boolean;
  boxPadding?: 'xs' | 'sm' | 'smd' | 'md' | 'mlg' | 'lg' | 'xl';
  renderActions?: () => React.ReactNode;
  parentQueryHierarchyId?: string;
  isOverflow?: boolean;
}

export const BareArticle = ({
  data: article,
  onContentLoaded,
  isDraft,
  noBorder = false,
  boxPadding = 'sm',
  renderActions,
  parentQueryHierarchyId,
  parentHierarchyId,
  index,
  isOverflow,
  // eslint-disable-next-line unused-imports/no-unused-vars
  isHistory
}: ArticleProps) => {
  const { data: contentConstants } = useContentConstants();
  const { trackContentIds, ignoreContentIds, getDuplicateClassName } = useContentIdTracking();
  const { generateHierarchyId } = useDataModelContext();
  const { state } = useHeaderData();
  const { trackContent, getAltSummFields } = useSummarianContext();
  const { shouldHighlight, clearItems } = useHighlight();

  const hierarchyId = generateHierarchyId(article, parentHierarchyId, index);
  const ignoreHierarchyIds = parentQueryHierarchyId ? [parentQueryHierarchyId] : [];

  const duplicateClassName = isDraft
    ? getDuplicateClassName(
        state.headerConfig.currentTheme ?? 'light',
        DuplicateItemType.CARD,
        article.attributes.id,
        ignoreHierarchyIds
      )
    : '';
  const overflowClassName = isOverflow ? styles.overflow : '';
  const itpNewContentClassName = shouldHighlight(article.attributes.id, HIGHLIGHT_GROUPS.ITP_NEW_ITEMS)
    ? styles.itpNewContent
    : '';
  const itpStaleContentClassName = shouldHighlight(article.attributes.id, HIGHLIGHT_GROUPS.ITP_STALE_ITEMS)
    ? styles.itpStaleContent
    : '';

  const allessehContent = useMemo(() => safelyParseContent<AllessehContent>(article.content), [article.content]);
  const actionButtons = renderActions?.();

  const altSummFields = getAltSummFields(allessehContent);

  const { headline } = altSummFields;
  const decodedHeadline = decodeHtmlEntities(headline);

  const debouncedAltSummFields = useDebouncedState(altSummFields, 300, ['proxy']);

  const urlToShow = getUrlToShow(debouncedAltSummFields);

  useEffect(() => {
    trackContent(allessehContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackContent]);

  useEffect(() => {
    if (isDraft) {
      trackContentIds(hierarchyId, article.attributes.id);

      return () => {
        ignoreContentIds(hierarchyId, article.attributes.id);
        clearItems(HIGHLIGHT_GROUPS.ITP_NEW_ITEMS);
      };
    }

    return () => {};
  }, [article.attributes.id, clearItems, hierarchyId, ignoreContentIds, isDraft, trackContentIds]);

  useEffect(() => {
    if (onContentLoaded) {
      onContentLoaded(index!, 1);
    }
  }, [allessehContent, index, onContentLoaded]);

  const { debouncedHandleMouseEnter, handleOnMouseLeave, showLargerImage } = useImage();
  const imageUrlThumbnail =
    useMemo(
      () =>
        resizeIMImage(
          urlToShow,
          {
            width: IM_WIDTH.THUMBNAIL,
            size: 1
          },
          getUrlToShowType(debouncedAltSummFields)
        ),
      [urlToShow, debouncedAltSummFields]
    ) ?? '';
  const imageUrlEnlarged = useMemo(() => resizeIMImage(urlToShow, { width: IM_WIDTH.ENLARGED }), [urlToShow]) ?? '';
  const enlargedHeight = IM_WIDTH.ENLARGED / extractSizeFromUrl(imageUrlEnlarged);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const displayDate = formatLocalDate(allessehContent.data.attributes.updated_datetime_utc ?? '');

  return (
    <Box
      padding={{ all: boxPadding }}
      className={classNames(itpNewContentClassName, itpStaleContentClassName, overflowClassName, duplicateClassName, {
        [styles.noBorder]: noBorder
      })}
      data-allesseh-id={allessehContent.data.id}
      data-testid="page-content-card-container"
      data-model-hierarchy-id={hierarchyId}
    >
      <Group gap="none" valign="start">
        <div
          onMouseEnter={debouncedHandleMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          className={styles.imageContainer}
          data-testid="content-card-image-container"
        >
          {showLargerImage && imageUrlThumbnail && (
            <div
              className={classNames(styles.imageEnlarged, { [styles.left]: isDraft })}
              style={{
                backgroundImage: `url('${imageUrlEnlarged}')`,
                height: enlargedHeight
              }}
              data-testid="larger-image"
            />
          )}
          {imageUrlThumbnail ? (
            <div
              style={{ backgroundImage: `url('${imageUrlThumbnail}')` }}
              className={classNames(styles.image, { [styles.noImage]: urlToShow })}
              data-testid="thumbnail"
            />
          ) : (
            brandIcon(article.attributes.product, contentConstants?.publicationMap)
          )}
        </div>

        <Typography
          componentEl="div"
          variant="bodytext"
          margin={{ left: 'sm' }}
          className={styles.headline}
          data-testid="content-card-headline-label"
        >
          {decodedHeadline}
          <TypeAndBrandToken allessehContent={allessehContent} />
          {allessehContent.data.attributes.content_status === 'embargo' && (
            <Token icon={IconDisabled as SvgComponent} margin={{ right: 'xs' }} color="lava" />
          )}
          <Typography inline variant="note" margin={{ left: 'xs' }}>
            {displayDate}
          </Typography>
        </Typography>

        {actionButtons && (
          <Dropdown
            padding={{ all: 'none' }}
            position="right"
            trigger={<IconThreeDotsVer color="asphalt" />}
            data-testid="page-content-ellipsis-icon"
          >
            {actionButtons}
          </Dropdown>
        )}
      </Group>
    </Box>
  );
};

export const Article = (articleProps: ArticleProps) => {
  const { generateHierarchyId } = useDataModelContext();
  const hierarchyId = generateHierarchyId(articleProps.data, articleProps.parentHierarchyId, articleProps.index);

  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <ErrorArticle
          allessehId={articleProps.data.attributes.id}
          hierarchyId={hierarchyId}
          boxPadding={articleProps.boxPadding}
          noBorder={articleProps.noBorder}
          resetErrorBoundary={resetErrorBoundary as ResetErrorBoundaryType}
          isDraft={articleProps.isDraft}
        />
      )}
    >
      <BareArticle {...articleProps} />
    </ErrorBoundary>
  );
};
